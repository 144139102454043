<template>
  <div>
    <b-card no-body>

      <b-row class="">

        <b-col cols="12"  xl="6">
          <div class="d-md-flex flex-row mb-2  text-md-left text-center">

            <b-button
                @click="$router.back()"
                class="shadow-sm font-weight-bold p-1 align-self-baseline float-left"
                variant="white">
              <feather-icon icon="ChevronLeftIcon" size="20"/>
            </b-button>

            <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                      class="mt-2 mr-3 mr-md-0"
                      size="100px"
            />
            <div class="m-2 font-weight-bolder profile-text">

              <h3 class="text-dark">Monika Smith</h3>

              <b-row>
                <b-col cols="12">
                  <label class="font-small-3 font-weight-light">Dietician</label>
                </b-col>

                <b-col cols="12   mt-1">
           <span class="mr-1">
                    <feather-icon icon="PhoneIcon"/>
                   078 4914 9377
        </span>
                  <span class="mr-1">
                    <feather-icon icon="MailIcon"/>
                  hello@tottenham.co.uk
        </span>

                  <span class="mr-1">
                    <feather-icon icon="PocketIcon"/>
                 AP234567B
        </span>

                </b-col>
              </b-row>

              <b-row class="pl-0 ">
                <b-col class="mt-1 font-weight-light" cols="12">
                  <b-badge class="mr-1" variant="light-info">
                    <span class="text-dark ">Dietology</span>
                  </b-badge>
                  <b-badge class="mr-1 my-1 my-md-0" variant="light-info">
                    <span class="text-dark"> Food Processing</span>
                  </b-badge>
                  <b-badge class="mr-1" variant="light-info">
                    <span class="text-dark"> Nutrition</span>
                  </b-badge>
                  <b-badge class="mr-1  mb-1 my-md-0" variant="light-info">
                    <span class="text-dark"> Biochemistry</span>
                  </b-badge>
                </b-col>

              </b-row>
            </div>
          </div>

        </b-col>


        <b-col cols="12"  xl="6" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">
          <b-row >
            <b-col cols="12" md="7" class="d-flex justify-content-center justify-content-xl-end">

              <div class="d-flex align-items-center pr-xl-4 pr-2">
                <b-avatar
                    variant="light-primary"
                    rounded
                >
                  <feather-icon
                      icon="ClockIcon"
                      size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h4 class="mb-0 font-weight-bolder">
                    50
                  </h4>
                  <small>Hours Worked</small>
                </div>
              </div>
              <div class="d-flex align-items-center pr-bx">
                <b-avatar
                    variant="light-primary"
                    rounded
                >
                  <feather-icon
                      icon="CreditCardIcon"
                      size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h4 class="mb-0 font-weight-bolder">
                    £2,650.00
                  </h4>
                  <small>Earned</small>
                </div>

              </div>

              
            </b-col>
<!--            <b-col cols="12" md="3" class="d-flex justify-content-start justify-content-md-end mt-2 mt-md-0 pl-3 pl-md-0">

                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                      variant="light-primary"
                      rounded
                  >
                    <feather-icon
                        icon="CreditCardIcon"
                        size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder">
                      £2,650.00
                    </h4>
                    <small>Earned</small>
                  </div>
                </div>

            </b-col>-->
            <b-col cols="12" md="5" class=" pr-3 pl-3 pl-md-0  my-2 my-md-0">
              <b-button
                  class="btn-block "
                  variant="primary"
              >
                <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                />
                <span class="align-middle">Send Message</span>
              </b-button>
            </b-col>
          </b-row>
<!--          <div class="ml-auto">

          <b-button
              class="mr-1"
              variant="primary"
          >
            <feather-icon
                icon="SendIcon"
                class="mr-50"
            />
            <span class="align-middle">Send Message</span>
          </b-button>

          </div>-->
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>

import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow} from 'bootstrap-vue'

export default {
  name: 'profile-image-bar',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return {
      mediaData: [
        {avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM'},
        {avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City'},
      ],
      avatars: [
        {avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper'},
      ],
    }
  },
}
</script>

<style scoped>

</style>
